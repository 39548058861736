import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookReader,
  faArrowCircleDown,
  faGraduationCap,
  faIdBadge,
  faBalanceScale,
  faPaperPlane,
  } from "@fortawesome/free-solid-svg-icons";

import Layout from '../components/layout'
import SEO from '../components/seo'
import indexStyles from './index.module.scss'
import topVideo from '../images/aikiishinryu02light.mp4'
import topImage from '../images/topAikido.webp'
//import topTrans from '../images/transparent.png'


const IndexPage = () =>{
  const data = useStaticQuery(graphql`
    query {
      makimono: file(relativePath: { eq: "images/makimono3.jpg" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth:1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      soke: file(relativePath: { eq: "images/sokeProfile.jpg" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth:1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        pagetitle="Home"
      />
      <div className={indexStyles.videoWrapper}>
        <div className={indexStyles.videoContainer}>
          <video preload="auto" muted autoPlay oncanplay="this.muted=true" loop poster={topImage}>
            <source src={topVideo} type="video/mp4" />
            <img src={topImage} title="Your browser does not support the <video> tag" alt="top image"></img>
          </video>
          <div className={indexStyles.overlay}></div>
          <div className={indexStyles.title}>
            <h1>Need REAL Aikido with REAL Theories?</h1>
            <h1>Aiki Ishin-ryu is for YOU.</h1>
            <h2><Link to ="/#detail-start">
              <FontAwesomeIcon
                icon={faArrowCircleDown}
                style={{ color: "#fff" }}
              />
            </Link></h2>
          </div>
        </div>
      </div>
      <a className={indexStyles.anchor} id="detail-start"></a>
      <ul className={indexStyles.section}>
        <li>
          <div className={indexStyles.imgFrameTop}>
            <Img fluid={data.makimono.childImageSharp.fluid} alt="makimono"/>
          </div>
        </li>
        <li>
          <div className={indexStyles.textBox}>
            <h2>Integration of Traditions and Modern Methodologies through "Aiki no Jutsu"</h2>
            <p>
              While many martial arts are aspiring to tournaments or sport, Aiki Ishin-ryu focuses on old, practical techniques from "Aiki no Jutsu" (相氣の術).
              Introduced by an experienced martial artist at the traditional Takeda-ryu Nakamura-ha, Aiki Ishin-ryu has always been innovating its training methods with modern knowledge and extra precaution not to let its role as a protector of history lose its substance.
              As a result, members of Aiki Ishin-ryu can access to the amassed, tremendous amount of authentic intellectual heritage and learn them with boosted efficiency.
            </p>
            <Link to="/about">
              <p>
                <FontAwesomeIcon
                  icon={faBookReader}
                  style={{ color: "#333" }}
                />
                &nbsp;
                Learn More
                </p>
            </Link>
          </div>
        </li>
      </ul>
      <ul className={indexStyles.sectionAlt}>
        <li>
          <div className={indexStyles.imgFrameSoke}>
            <Img fluid={data.soke.childImageSharp.fluid} alt="soke"/>
          </div>
        </li>
        <li>
          <div className={indexStyles.textBox}>
            <h3>Soke (宗家: the originator)</h3>
            <h2>Yoshinaka Suzuki (鈴木喜中)</h2>
            <p>The founder and the head of Aiki Ishin-ryu</p>
            <p>The head Shihan (師範: teacher) of NPO International Aikido Association</p>
            <p>Certified Sports Leader of Japan Sport Association</p>
            <p>Qualified Leadership Chief in General Foundation of Japan Leadership and Education Association</p>
            <p>Ex-Joden Shihan (上伝師範) at Takeda-ryu Nakamura-ha (武田流中村派)</p>
            <p>Ex-Kancho (館長: owner of a Dojo) in Nihon Sobudo Rengokai (日本綜武道連合会) at the youngest age at that time</p>
            <p>5 times of Championship in Sogo Randori Shiai (綜合乱取試合: integrated competition) at All Japan Tournament of Takeda-ryu Nakamura-ha</p>
            <p>Honorary memeber of International Traditional Budo Federation</p>
          </div>
        </li>
      </ul>
      <ul className={indexStyles.sectionThree}>
        <li>
          <h1>
            <FontAwesomeIcon
              icon={faGraduationCap}
              style={{ color: "#333" }}
            />
          </h1>
          <h2>Education</h2>
          <p>
            In addition to martial art techniques, related Japanese and history are also taught.
            Certificates will be granted to those who pass our rigorous examinations.
          </p>
        </li>
        <li>
          <h1>
            <FontAwesomeIcon
              icon={faIdBadge}
              style={{ color: "#333" }}
            />
          </h1>
          <h2>Instructors</h2>
          <p>
            Our techniques will be taught only by our instructors with fixed-term licenses guaranteeing substantial abilities.
            The list of all instructors are administered by our headquarter dojo in Tokyo and is open to any member.
          </p>
        </li>
        <li>
          <h1>
            <FontAwesomeIcon
              icon={faBalanceScale}
              style={{ color: "#333" }}
            />
          </h1>
          <h2>Regulations</h2>
          <p>
            Based on our clearly stated universal rules, all of our members' chance to practice are equally protected.
            Any act of corruption is to be punished immediately and strictly.
          </p>
        </li>
      </ul>
      <div className={indexStyles.toContact}>
        <h1>Ready to learn the REAL Aikido?</h1>
        <Link to="/contact">
          <p>
            <FontAwesomeIcon
              icon={faPaperPlane}
              style={{ color: "#333" }}
            />
            &nbsp;
            Contact us!
          </p>
        </Link>
      </div>
    </Layout>
  )
}

export default IndexPage